$font-path: '~react-widgets/lib/fonts';
$img-path: '~react-widgets/lib/img';
@import '~react-widgets/lib/scss/react-widgets';

body {
  section {
    margin: 8px 0;
  }
}

table {
  &.table {
    //width: unset !important;
  }
  th {
    &.sortable {
      cursor: pointer !important;
    }
    &.active {
      font-style: italic;
    }
    &:not(.active) {
      > i {
        color: #ccc;
      }
    }
  }
  td {
  }
  th, td {
    &.amount {
      text-align: right !important;
    }
    &.money {
      text-align: right !important;
    }
    &.duration {
      text-align: right !important;
    }
    &.left {
      text-align: center !important;
    }
    &.center {
      text-align: center !important;
    }
    &.right {
      text-align: right !important;
    }
    &.actions {
      text-align: center !important;
    }
    &.fit {
      white-space: nowrap !important;
    }
    &.actions {
      @media only screen and (min-width : 1280px) {
        white-space: nowrap !important;
      }
      > button {
        margin: 0 0 0 0.2rem !important;
        @media only screen and (max-width : 1280px) {
          margin: 0 0 0.2rem 0 !important;
        }
      }
    }
  }
}

button {
  &.link {
    display: inline;
    border: none;
    border-radius: 0;
    background: none;
    box-shadow: none;
    -webkit-box-shadow: none;
    padding: 0;
    vertical-align: inherit;
    line-height: inherit;
    &:hover, &:focus {
      border: none !important;
      background: none !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
    }
  }
}

input[type=number] {
  text-align: right !important;
}

input[type=number] {
  -moz-appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.clickable {
  cursor: pointer;
}

th[draggable=true] {
  cursor: grab;
}

.trunc {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &.trunc4 {
    max-width: 4em;
  }
  &.trunc6 {
    max-width: 6em;
  }
  &.trunc8 {
    max-width: 8em;
  }
  &.trunc10 {
    max-width: 10em;
  }
  &.trunc12 {
    max-width: 12em;
  }
  &.trunc14 {
    max-width: 14em;
  }
  &.trunc16 {
    max-width: 16em;
  }
  &.trunc20 {
    max-width: 20em;
  }
  &.trunc24 {
    max-width: 24em;
  }
}

.date-selector {
  .form {
    & > * {
      margin: 0 4px;
      vertical-align: middle;
    }
  }
}

input[type=date]::-webkit-outer-spin-button,
input[type=date]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=date] {
  -moz-appearance:textfield;
}

.tooltip-anchor {
  cursor: default;
}

$successColor: #38C172;
$infoColor: #3490DC;
$warningColor: #FAAD63;
$errorColor: #E3342F;

//@import "general";
//@import "tabs";
//@import "overrides";
//@import "table";
//@import "form";
//@import "companies";
@import "print";
@import "booking";
//@import "reports";

.ui {
    &.container {
        &.app {
            margin-top: 4em;
        }
    }
    &.segment {
        .grid {
            .totals {
                font-size: 1.1rem;
                > p {
                    display: inline;
                    color: rgba(0,0,0,0.6);
                    &:after {
                        content: ' - ';
                    }
                    &:last-child {
                        &:after {
                            content: none;
                        }
                    }
                    span {
                      font-weight: bold;
                      color: rgba(0,0,0,0.87);
                    }
                }
            }
            .filters {
                > * {
                    margin-left: 0.4rem;
                }
                .fields {
                  margin-bottom: 0;
                }
            }
        }
    }
    &.dropdown {
        .menu {
            > .item {
                font-size: inherit !important;
            }
        }
    }
    &.form {
        .field {
            &.static {
                label + span {
                    display: inline-block;
                    margin: 0.5em 0.25em 0;
                }
            }
        }
    }
}


.key {
    text-transform: capitalize;
    display: inline-block;
    > span {
        vertical-align: middle;
        display: inline-block;
        line-height: 2.4em;
        height: 2.4em;
        margin-right: 0.5em;
        &.swatch {
            width: 2.4em;
            border: 1px solid;
            margin-right: 0.5em;
            border-radius: 3px;
        }
    }
}
