@media print {
    body {
        font-size: 10px;
        #root {
            > .grid {
                margin-top: 0.25rem;
                .container {
                    &.header {
                        display: none;
                    }
                    &.app {
                        margin-top: 0;
                        > .message {
                            display: none;
                        }
                        .segment {
                            &.filter {
                                display: none;
                            }
                        }
                        tr {
                            page-break-inside: avoid;
                        }
                    }
                }
            }
        }
    }
}
