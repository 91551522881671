table#bookingsTable {
    tr {
        &.paid {
            color: $successColor;
        }
        &.unpaid {
            color: $warningColor;
        }
        &.overdue {
            color: $errorColor;
        }
        td {
            vertical-align: top;
        }
    }
}
